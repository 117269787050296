<template>
  <div v-if="isOpen" class="notifier_container">
    <uds-toast
      :type="type"
      :content-text="content"
      :class="isDone ? 'slide-top' : 'slide-bottom'"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";

const notifierStore = useNotifierStore();
const isDone = ref(false);

const { type, content } = storeToRefs(notifierStore);
const isOpen = computed<boolean>(() => notifierStore.isOpen);
</script>

<style lang="scss" scoped>
.notifier_container {
  height: 80px;
  width: 100%;
  position: absolute;
  z-index: 99999;
  top: 0;
  display: flex;
  justify-content: center;
}

.slide-bottom {
  animation: slide-bottom 0.5s ease-in-out both;
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
}

.slide-top {
  animation: slide-top 0.5s ease-in-out both;
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
  }
}
</style>
